import React from 'react';
import {Card, Collapse} from 'antd';

function TemplateCard(props) {
    const items = [{
        key: 0,
        label: <>{props.card_title}</>,
        children: props.children,
        style: {
            borderTop: 0,
            borderBottom: "8px",
            padding: "0px",
            fontSize: "16px",
            maxHeight: "30em",
            overflowY: "auto",
        }
    },]
    let collapseProps = {...props}
    delete collapseProps.children
    return (
        <div style={{marginTop: "1em"}}>
            <Card hoverable={true}
                  styles={{
                      header: {cursor: "default"}, body: {
                          padding: 0,
                          boxShadow: "0 2px 8px rgba(0,0,0,.11)",
                      }
                  }}
                  style={{cursor: "default"}}>
                <Collapse defaultActiveKey={[0]}
                          style={{borderLeft: 0, borderRight: 0, borderTop: 0}}
                          items={items}
                          {...collapseProps}>
                </Collapse>
            </Card>
        </div>
    )
}


export default TemplateCard