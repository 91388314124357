import React, {useEffect, useState} from "react";
import {Col, Checkbox, Radio, Row, Space, Typography, Select, Button, Modal, Form, Input} from "antd";
import {atom, useAtom} from "jotai";
import {useEasyGetRequest, useEasyGetRequestDebounceWait} from "../Global/Message";
import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useRequest} from "ahooks";

const defaultChromMethod = {chromType: null, wikiId: null, name: null, description: null, is_reference: false};
export const atomChromMethod = atom(defaultChromMethod);
const atomShowModal = atom(false);

const ModalForNewMethod = () => {
    const [stateChromMethod, setChromMethod] = useAtom(atomChromMethod);
    const [stateShowModal, setShowModal] = useAtom(atomShowModal);
    const [form] = Form.useForm();

    const [getRequest, messageContextHolder] = useEasyGetRequest(
        urlMassWiki.chromMethod.createMethod,
        () => {
        },
        (data) => setChromMethod({
            ...stateChromMethod,
            wikiId: data.wiki_id,
            name: data.name,
            description: data.description,
        }),
        "chromMethod.createMethod", "Creating new chromatography method..."
    )

    const handleCancel = () => {
        setShowModal(false)
    }
    const handleOk = () => {
        const submitData = form.getFieldsValue();
        // console.log("form", submitData)
        form.submit();
    }
    const formSubmitSuccess = (values) => {
        // console.log('Success:', values)
        const submitData = form.getFieldsValue();
        getRequest.run(submitData.methodName, submitData.description)
        setShowModal(false)
    }
    const formSubmitFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    return <>
        {messageContextHolder}
        <Modal title="Create new chromatography method" open={stateShowModal} width={650}
               destroyOnClose={true} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} name={"newMethod"} labelCol={{span: 6}} wrapperCol={{span: 18}} style={{marginTop: "2em"}}
                  onFinish={formSubmitSuccess}
                  onFinishFailed={formSubmitFailed}
            >
                <Form.Item label="Method Name" name="methodName"
                           rules={[{required: true, message: 'Please input method name!'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Description" name="description"
                           rules={[{required: false, message: 'Please input method description!'}]}>
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

const SetWikiIdForNewMethod = () => {
    const [stateChromMethod,] = useAtom(atomChromMethod);
    const [, setShowModal] = useAtom(atomShowModal);
    return <>
        <Row>
            <Col span={2}/>
            <Col span={22}>
                <Space>
                    {
                        stateChromMethod.wikiId ? <>
                            <Typography.Text style={{whiteSpace: "nowrap"}}>
                                Chromatography Method: {stateChromMethod.name}
                            </Typography.Text>
                        </> : <>
                        </>
                    }
                    <Button onClick={() => setShowModal(true)}>Create new method</Button>
                </Space>
            </Col>
        </Row>
    </>
}

const SetWikiIdForExistedMethod = () => {
    const [stateChromMethod, setChromMethod] = useAtom(atomChromMethod);
    const [stateDisplayNameList, setDisplayNameList] = useState([]);
    const [stateIdToData, setIdToData] = useState({});

    const [getRequest, messageContextHolder] = useEasyGetRequestDebounceWait(
        urlMassWiki.chromMethod.searchMethod,
        () => {
            setIdToData({})
        },
        (data) => {
            setIdToData(data.map((d) => ({[d.wiki_id]: d})).reduce((a, b) => ({...a, ...b}), {}))
            setDisplayNameList(data)
        },
        500,
        "chromMethod.searchMethod", "Searching existed chromatography method..."
    )
    const handleSearch = (newValue) => {
        getRequest.run(newValue)
    };

    const handleChange = (newValue) => {
        const method = stateIdToData[newValue] || {}
        setChromMethod({
            ...stateChromMethod,
            wikiId: method.wiki_id,
            name: method.name,
            description: method.description
        })
    };

    return <Row>
        {messageContextHolder}
        <Col span={2}/>
        <Col span={22} style={{width: "100%", display: "flex"}}>
            <div style={{width: "100%", display: "flex", alignItems: "center"}}>
                <Typography.Text style={{whiteSpace: "nowrap", marginRight: "1em"}}>
                    Chromatography Method:
                </Typography.Text>
                <Select placeholder="Type the method name or method mass wiki id to search"
                        showSearch
                        suffixIcon={null}
                        filterOption={false}
                        value={stateChromMethod.wikiId}
                        notFoundContent={null}
                        options={(stateDisplayNameList || []).map((d) => ({
                            value: d.wiki_id,
                            label: d.name
                        }))}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        style={{width: "100%", minWidth: "10em", display: "inline-block"}}></Select>
                {
                    stateChromMethod.wikiId ?
                        <Button onClick={() => setChromMethod({
                            ...defaultChromMethod,
                            chromType: "existed"
                        })}>Clear</Button> : null
                }
            </div>
        </Col>
    </Row>

}

export default () => {
    const [stateChromMethod, setChromMethod] = useAtom(atomChromMethod);
    const [, setShowModal] = useAtom(atomShowModal);

    useEffect(() => {
        console.log("stateChromMethod:", stateChromMethod)
    }, [stateChromMethod])
    const onUserSelectionChange = (e) => {
        setChromMethod({...defaultChromMethod, chromType: e.target.value})
    }
    return <div align={"left"}>
        {/*<Divider orientation="center">Chromatography Method</Divider>*/}
        <Space direction="vertical" style={{width: "100%"}}>
            <Radio value={"na"} onChange={onUserSelectionChange}
                   checked={stateChromMethod.chromType === "na"}>
                This study do not use common chromatography methods. </Radio>
            <Radio value={"new"} onChange={(e) => {
                onUserSelectionChange(e)
                setShowModal(true)
            }} checked={stateChromMethod.chromType === "new"}>This study use a new chromatography method. </Radio>
            {
                stateChromMethod.chromType === "new" ? <SetWikiIdForNewMethod/> : null
            }
            <ModalForNewMethod/>
            <Radio value={"existed"} onChange={onUserSelectionChange}
                   checked={stateChromMethod.chromType === "existed"}>This study use an existed chromatography method in
                MassWiki, MassWiki will use this method for retention time alignment
                .</Radio>
            {
                stateChromMethod.chromType === "existed" ? <SetWikiIdForExistedMethod/> : null
            }
            {
                (stateChromMethod.chromType || "na") !== "na" ?
                    <Checkbox style={{marginTop: "2em"}} checked={stateChromMethod.is_reference}
                              onChange={(e) => setChromMethod({...stateChromMethod, is_reference: e.target.checked})}>
                        Add spectra in this file as reference spectra for this chromatography method.
                    </Checkbox> : null
            }
        </Space>
    </div>
}