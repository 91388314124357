import {Col, Row, Tabs, Tag} from 'antd';
import React, {useEffect, useState} from "react";
import {atom, useAtom} from "jotai";
import {useRequest} from "ahooks";

import VirtualTable from "../../../Library/VirtualTable";
import {atomGlobalSpectrumData,} from "../../../Data/Atoms";
import {atomGlobalReferenceSpectrumData} from "../../../Data/Atoms";
import {atomShowModal, atomSubmissionData} from "../UserAnnotation/UserAnnotationSubmitModal";
import {
    atomLibrarySearchTableData,
    atomLibrarySearchTableDataTrigger,
    atomLibrarySearchSelectedDataTrigger
} from "./LibrarySearchResultData";
import LibrarySearchResultData from "./LibrarySearchResultData";
import {zip} from "../../../Library/Tools";


const getColumn = (stateGlobalReferenceSpectrumData, setPageSubmissionData) => {
    return [
        {
            title: 'DB', dataIndex: 'db', key: 'db', ellipsis: false, width: 60,
        }, {
            title: 'ID', dataIndex: 'db_id', key: 'db_id', ellipsis: false, width: 80
        }, {
            title: 'Name', dataIndex: 'name', key: 'name', ellipsis: false, width: 120
        }, {
            title: 'Adduct', dataIndex: 'adduct', key: 'adduct', ellipsis: false, width: 80
        }, {
            title: 'Entropy similarity',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            defaultSortOrder: 'descend',
            ellipsis: false,
            width: 80,
            render: (_, record) => record.score.toFixed(3),
        }, {
            title: 'Delta mass',
            dataIndex: 'delta_mz',
            key: 'delta_mz',
            sorter: (a, b) => a.delta_mz - b.delta_mz,
            ellipsis: false,
            width: 50,
            render: (_, record) => record.delta_mz.toFixed(3),
        }, {
            title: 'Link', dataIndex: 'link', key: 'link', ellipsis: false, width: 50, render: (_, record) => (<>
                {record.link ? <a href={record.link} target="_blank">View</a> : <span>-</span>} </>),
        }, {
            title: "", width: 70,
            render: (_, record) => (
                stateGlobalReferenceSpectrumData.wiki_id === record.key ?
                    <Tag color={"red"} onClick={() => setPageSubmissionData(record)}>
                        Assign annotation
                    </Tag> : <>
                    </>
            )
        }
    ]
}

export default () => {
    const [stateGlobalSpectrumData,] = useAtom(atomGlobalSpectrumData);

    ////////////////////////////////////////////////////////////////////////////////
    // For submission modal
    const [, setAtomShowModal] = useAtom(atomShowModal);
    const [, setAtomSubmissionData] = useAtom(atomSubmissionData);
    const setPageSubmissionData = (record) => {
        console.log(record)
        setAtomSubmissionData({
            type: "library_search",
            mol_name: record.name || "",
            mol_adduct: record.adduct || "",
            mol_smiles: record.smiles || "",
            db: record.db || "",
            db_id: record.db_id || "",
            score: record.score || 0,
            delta_mz: record.delta_mz || 0,
        })
        setAtomShowModal(true)
    }

    ////////////////////////////////////////////////////////////////////////////////
    // For search type
    const [stateSearchType, setSearchType] = useState("identity_search");
    const [stateSearchResults, setSearchResults] = useState({});
    // Dealing with different search types
    useEffect(() => {
        const search_result = (stateGlobalSpectrumData.analysis || {}).library_search;
        if (search_result) {
            setSearchResults(search_result);
            if ((stateSearchResults.identity_search || []).length > 0) {
                setSearchType("identity_search")
            } else if ((stateSearchResults.open_search || []).length > 0) {
                setSearchType("open_search")
            } else if ((stateSearchResults.neutral_loss_search || []).length > 0) {
                setSearchType("neutral_loss_search")
            } else if ((stateSearchResults.hybrid_search || []).length > 0) {
                setSearchType("hybrid_search")
            } else {
                setSearchType("identity_search")
            }
        } else {
            setSearchResults({});
        }
    }, [stateGlobalSpectrumData.analysis]);

    // //////////////////////////////////////////////////////////////////////////////
    // For get library search table data
    const [stateLibrarySearchTableDataTrigger, setLibrarySearchTableDataTrigger] = useAtom(atomLibrarySearchTableDataTrigger);
    useEffect(() => {
        const librarySearchScoreList = stateSearchResults[stateSearchType] || [];
        if (librarySearchScoreList && librarySearchScoreList.length > 0) {
            setLibrarySearchTableDataTrigger({
                ...stateLibrarySearchTableDataTrigger, wikiIdList: librarySearchScoreList.map((d) => d[0])
            })
        } else {
            setLibrarySearchTableDataTrigger({...stateLibrarySearchTableDataTrigger, wikiIdList: []});
        }
    }, [stateSearchResults, stateSearchType])

    // For display library data
    const [stateTableData, setTableData] = useState([]);
    const [stateLibrarySearchTableData,] = useAtom(atomLibrarySearchTableData);
    useEffect(() => {
        const userLibrarySearchScoreList = stateSearchResults[stateSearchType] || [];
        const annotationData = zip(stateLibrarySearchTableData, userLibrarySearchScoreList);
        const globalSpectrumPrecursorMz = (stateGlobalSpectrumData.spectrum || {}).precursor_mz;
        const searchResultData = annotationData.map((data, index) => {
            const info = data[0];
            const score = data[1];
            info.key = info.wiki_id;
            if (info.db === "MassBank.us") {
                info.link = `https://mona.fiehnlab.ucdavis.edu/spectra/display/${info.id}`;
            } else if (info.db === "GNPS") {
                info.link = `https://gnps.ucsd.edu/ProteoSAFe/gnpslibraryspectrum.jsp?SpectrumID=${info.id}`;
            }
            info.score = score[1];
            info.delta_mz = info.precursor_mz - globalSpectrumPrecursorMz
            return info;
        })
        // console.log("searchResultData", searchResultData)
        setTableData(searchResultData);
    }, [stateLibrarySearchTableData])

    ////////////////////////////////////////////////////////////////////////////////
    // For table column
    const [stateGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrumData);
    const column = getColumn(stateGlobalReferenceSpectrumData, setPageSubmissionData);
    const [stateLibrarySearchSelectedDataTrigger, setLibrarySearchSelectedDataTrigger] = useAtom(atomLibrarySearchSelectedDataTrigger);

    const tabExtra = <>
        <Tag color={"red"} onClick={() => setPageSubmissionData({})}>
            Add annotation
        </Tag>
    </>


    return <>
        <LibrarySearchResultData/>
        <Row>
            <Col span={24}>
                <Tabs defaultActiveKey="identity_search" centered onChange={(k) => setSearchType(k)}
                      tabBarExtraContent={tabExtra}
                      items={[{
                          key: "identity_search", label: "Identity search",
                          disabled: ((stateSearchResults || {}).identity_search || []).length === 0
                      }, {
                          key: "open_search", label: "Open search",
                          disabled: ((stateSearchResults || {}).open_search || []).length === 0
                      }, {
                          key: "neutral_loss_search", label: "Neutral loss search",
                          disabled: ((stateSearchResults || {}).neutral_loss_search || []).length === 0
                      }, {
                          key: "hybrid_search", label: "Hybrid search",
                          disabled: ((stateSearchResults || {}).hybrid_search || []).length === 0
                      }]}/>
                <VirtualTable
                    vid={"spectra-result-table"}
                    // loading={getLibraryData.loading}
                    onRow={record => {
                        return {
                            onClick: event => {
                                if (record.key !== stateGlobalReferenceSpectrumData.wiki_id) {
                                    setLibrarySearchSelectedDataTrigger({
                                        wikiId: `${record.key}`,
                                        version: stateLibrarySearchSelectedDataTrigger.version + 1
                                    });
                                }
                            }, onMouseEnter: event => {
                            },
                        };
                    }}
                    rowClassName={record => {
                        return (stateGlobalReferenceSpectrumData.wiki_id === `${record.key}`) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateTableData}/>
            </Col>
        </Row>
    </>;
};
