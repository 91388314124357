import {Col, Row, Space, Timeline, Tabs, message, notification, Button} from 'antd';
import React, {useState, useContext, useEffect} from 'react';
import {atom, useAtom} from "jotai";
import {useRequest} from "ahooks";

import {atomGlobalSpectrumData} from "../../Data/Atoms";
import {atomGlobalReferenceSpectrumData} from "../../Data/Atoms";
import {urlMassWiki} from "../../Config/MassWikiUrl";
import VirtualTable from "../../Library/VirtualTable";
import {useEasyGetRequest} from "../Global/Message";

const columnsTopN = [
    {
        title: 'Resource',
        dataIndex: 'resource',
        key: 'resource',
        ellipsis: true,
        width: 100
    }, {
        title: 'Study ID',
        dataIndex: 'study_id',
        key: 'study_id',
        ellipsis: true,
        width: 60
    }, {
        title: 'File name',
        dataIndex: 'file_name',
        key: 'file_name',
        ellipsis: true,
        width: 200
    }, {
        title: 'Scan',
        dataIndex: 'scan',
        key: 'scan',
        ellipsis: true,
        width: 50
    }, {
        title: 'Entropy Similarity',
        dataIndex: 'score',
        key: 'score',
        ellipsis: true,
        width: 80,
        render: (_, record) => record.score.toFixed(3),
    }, {
        title: 'Link', dataIndex: 'link', key: 'link', ellipsis: false, width: 50, render: (_, record) => (<>
            {record.link ? <a href={record.link} target="_blank">View</a> : <span>-</span>} </>),
    }]

const columnsHighScore = [
    {
        title: 'Resource',
        dataIndex: 'resource',
        key: 'resource',
        width: 150
    }, {
        title: 'Study ID',
        dataIndex: 'study_id',
        key: 'study_id',
        width: 100
    }, {
        title: 'Detected number',
        dataIndex: 'count',
        key: 'count',
        width: 60
    }, {
        title: 'Link', dataIndex: 'link', key: 'link', ellipsis: false, width: 50, render: (_, record) => (<>
            {record.link ? <a href={record.link} target="_blank">View</a> : <span>-</span>} </>),
    }]

export const atomUniverseSearchResult = atom({})

export default (props) => {
    const [stateTopNData, setTopNData] = useState([])
    const [stateAggregatedData, setAggregatedData] = useState([])

    // Download the annotations
    const [stateAtomGlobalSpectrumData] = useAtom(atomGlobalSpectrumData);
    const [stateAtomUniverseSearchResult, setAtomUniverseSearchResult] = useAtom(atomUniverseSearchResult);
    useEffect(() => {
        console.log("stateAtomUniverseSearchResult", stateAtomUniverseSearchResult)
    }, [stateAtomUniverseSearchResult]);

    const [getSearchUniverseIdentity, messageGetSearchUniverseIdentity] = useEasyGetRequest(
        urlMassWiki.universe.search_universe_identity,
        () => setTopNData([]),
        (data) => {
            setAtomUniverseSearchResult(data)
            const dataTopN = data.top_n;
            const dataHighScore = data.high_score;
            // Add key to the data
            dataTopN.forEach((item, index) => {
                item.key = index;
            })
            dataHighScore.forEach((item, index) => {
                item.key = index;
            })
            setTopNData(dataTopN)
            setAggregatedData(dataHighScore)
        },
        "urlMassWiki.universe.search_universe_identity", "Searching all the public repositories, this might need a few seconds...")
    // const getSearchUniverseIdentity = useRequest(urlMassWiki.analysis.search_universe_identity, {
    //     manual: true,
    // });
    useEffect(() => {
        const specData = stateAtomGlobalSpectrumData.spectrum || {};
        if (specData.peaks && specData.peaks.length > 0) {
            // console.log(getAtomUpperSpectrumData)
            getSearchUniverseIdentity.run({
                charge: specData.charge,
                precursor_mz: specData.precursor_mz,
                peaks: specData.peaks,
            });
        }
    }, [stateAtomGlobalSpectrumData])

    const [stateSelectedRow, setSelectedRow] = useState({type: "none", id: "none"});
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrumData);

    const [getUniverseSpectrum, messageGetUniverseSpectrum] = useEasyGetRequest(
        urlMassWiki.universe.get_universe_spectrum,
        () => setGlobalReferenceSpectrumData({}),
        (data) => {
            console.log("getUniverseSpectrum", data)
            setGlobalReferenceSpectrumData({data: data, spectrum: data})
        },
        "urlMassWiki.universe.get_universe_spectrum", "Loading the spectrum data...")


    useEffect(() => {
        if (stateSelectedRow.type == "unique_scan_id" && stateSelectedRow.id != "none") {
            getUniverseSpectrum.run(stateSelectedRow.id);
        }
    }, [stateSelectedRow])

    let items = [
        {
            key: 'aggregated',
            label: 'Study matches',
            children: <><VirtualTable
                vid={"universe-search-table-aggregated"}
                loading={getSearchUniverseIdentity.loading ? {
                    tip: <>Searching all the public repositories, this might need a few seconds...</>
                } : false}

                onRow={record => {
                    return {
                        onClick: event => {
                            setSelectedRow({type: "study_id", id: `${record.study_id}`});
                            // console.log(`${record.unique_scan_id}`);
                        },
                    };
                }}
                rowClassName={record => {
                    return (stateSelectedRow.id === `${record.study_id}`) ? "row-active" : "";
                }}
                height={200}
                size={'small'}
                columns={columnsHighScore} dataSource={stateAggregatedData}/></>,
        },
        {
            key: 'topn',
            label: 'Top 100 spectra matches',
            children: <><VirtualTable
                vid={"universe-search-table-topn"}
                loading={getSearchUniverseIdentity.loading ? {
                    tip: <>Searching all the public repositories, this might need a few seconds...</>
                } : false}

                onRow={record => {
                    return {
                        onClick: event => {
                            setSelectedRow({type: "unique_scan_id", id: `${record.unique_scan_id}`});
                            // console.log(`${record.unique_scan_id}`);
                        },
                    };
                }}
                rowClassName={record => {
                    return (stateSelectedRow.id === `${record.unique_scan_id}`) ? "row-active" : "";
                }}
                height={200}
                size={'small'}
                columns={columnsTopN} dataSource={stateTopNData}/></>,
        },
        {
            key: 'metagpt',
            label: 'MetaGPT',
            children: <>
                <Row justify={"center"}>
                    <Col span={18}>
                        {stateAtomUniverseSearchResult.gpt_prompt ? <>
                            <>
                                <Space>1. Click the "Copy" button to copy the prompt to your clipboard:
                                    <Button type="primary" onClick={() => {
                                        const text = stateAtomUniverseSearchResult.gpt_prompt;

                                        const textArea = document.createElement("textarea");
                                        textArea.value = text;

                                        // Move textarea out of the viewport so it's not visible
                                        textArea.style.position = "absolute";
                                        textArea.style.left = "-999999px";

                                        document.body.prepend(textArea);
                                        textArea.select();

                                        try {
                                            document.execCommand('copy');
                                            message.success("Copied to clipboard")
                                        } catch (error) {
                                            message.error("Failed to copy to clipboard")
                                            console.error(error);
                                        } finally {
                                            textArea.remove();
                                        }
                                        //
                                        // navigator.clipboard.writeText(text).then(function () {
                                        //     message.success("Copied to clipboard")
                                        // }, function () {
                                        //     message.error("Failed to copy to clipboard")
                                        // });
                                    }}>Copy</Button></Space>
                            </>
                            <>
                                <Space>
                                    2. Go to the MetaGPT by clicking the link (you need to have a ChatGPT Plus account):
                                    <a href="https://chat.openai.com/g/g-OZrnhkA80-metagpt"
                                       target="_blank">https://chat.openai.com/g/g-OZrnhkA80-metagpt</a>
                                </Space>
                            </>
                            <>
                                <Space>
                                    3. Paste the prompt to the text box and click "Send message" to the MetaGPT.
                                </Space>
                            </>
                        </> : <>
                            No matched spectra are found in the public repositories, therefore, the MetaGPT is not
                            available.
                        </>}
                    </Col>
                </Row>
            </>,
        }
    ];
    items.pop();
    return <>
        {messageGetUniverseSpectrum}
        {messageGetSearchUniverseIdentity}
        <Row justify={"center"}>
            <Col span={22}>
                <Tabs centered defaultActiveKey="aggregated" items={items} />
            </Col>
        </Row>
    </>
}