import React from "react";

import AnalysisData from "./AnalysisData";
import SpectrumData from "./SpectrumData";
import AnnotationData from "./AnnotationData";
import WikiId from "./WikiId";

export default () => {
    return <>
        <WikiId/>
        <AnalysisData/>
        <SpectrumData/>
        <AnnotationData/>
    </>;
}
