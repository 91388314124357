import React, {useEffect} from "react";
import {useAtom} from 'jotai'

import {urlMassWiki} from "../Config/MassWikiUrl";
import {atomGlobalAnnotationDataTrigger, atomGlobalSpectrumData, atomGlobalAnnotationData} from "./Atoms";
import {useEasyGetRequest} from "../Pages/Global/Message";

export default () => {
    const [stateAtomGlobalSpectrum,] = useAtom(atomGlobalSpectrumData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomGlobalAnnotationData
    const [stateGlobalAnnotationData, setGlobalStateAnnotationData] = useAtom(atomGlobalAnnotationData);
    const [stateGlobalAnnotationDataTrigger,] = useAtom(atomGlobalAnnotationDataTrigger);

    // Get annotation data
    const [getAnnotationDataBySpectrumUuid, messageGetAnnotationDataBySpectrum] =
        useEasyGetRequest(
            urlMassWiki.annotation.getBySpectrumUuid,
            () => setGlobalStateAnnotationData([]),
            (data) => setGlobalStateAnnotationData(data),
            "urlMassWiki.annotation.getBySpectrumUuid", "Loading annotation data..."
        );
    useEffect(() => {
        console.log("atomGlobalAnnotationData", stateGlobalAnnotationData)
    }, [stateGlobalAnnotationData]);

    useEffect(() => {
        if (stateAtomGlobalSpectrum.spec_uuid && stateAtomGlobalSpectrum.spec_uuid > 0) {
            getAnnotationDataBySpectrumUuid.run(stateAtomGlobalSpectrum.spec_uuid)
        }
    }, [stateAtomGlobalSpectrum, stateGlobalAnnotationDataTrigger]);

    return <>
        {messageGetAnnotationDataBySpectrum}
    </>
}

