/* global BigInt */
import {Col, Row, Table} from 'antd';
import React, {useEffect, useState} from "react";
import {useAtom} from "jotai";

import {usePlotMolecule} from "../../Library/PlotMolecule";
import {atomGlobalReferenceSpectrumData} from "../../Data/Atoms";
import {useEasyGetRequest} from "../Global/Message";
import {urlMassWiki} from "../../Config/MassWikiUrl";

const column = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: '30%',
    }, {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        ellipsis: true,
        render: (text) => <div style={{whiteSpace: "pre-line"}}>
            {text}
        </div>

    }]
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const Main = () => {
    const [stateGlobalReferenceSpectrumData,] = useAtom(atomGlobalReferenceSpectrumData);
    const [contextPlotMolecule, setAtomPlotMoleculeSmiles] = usePlotMolecule({width: 450, height: 200});

    const [stateChromMethodData, setStateChromMethodData] = useState([]);
    const [getMethodByWikiId, messageGetMethodByWikiId] = useEasyGetRequest(urlMassWiki.chromMethod.getMethodByWikiId,
        () => setStateChromMethodData([]),
        (data) => {
            setStateChromMethodData([{
                name: "Chromatography method",
                value: data.name,
                key: "chromatography_method"
            }]);
        },
        "urlMassWiki.chromMethod.getMethodByWikiId", "Loading chrom method information...");

    const [stateReferenceSpectrumData, setStateReferenceSpectrumData] = useState([]);
    useEffect(() => {
        console.log("stateGlobalReferenceSpectrumData", stateGlobalReferenceSpectrumData);
        if (stateGlobalReferenceSpectrumData) {
            const data = {...stateGlobalReferenceSpectrumData.metadata};
            if (data.chrom_wiki_id) {
                getMethodByWikiId.run(data.chrom_wiki_id);
                delete data['chrom_wiki_id'];
            } else {
                setStateChromMethodData([]);
            }
            delete data['peaks'];
            // Remove the key starting with underscore
            Object.keys(data).forEach(key => {
                if (key.startsWith('_') || data[key] === null || data[key] === "") {
                    delete data[key];
                }
            });
            setAtomPlotMoleculeSmiles(data.smiles || data.mol_smiles || "");
            const display = Object.entries(data).map(([key, value]) => {
                return {
                    name: capitalizeFirstLetter(key),
                    value: value.constructor == Object ? JSON.stringify(value) : value,
                    key: key
                }
            })
            setStateReferenceSpectrumData(display);
        } else {
            setStateReferenceSpectrumData([]);
        }
    }, [stateGlobalReferenceSpectrumData]);

    const [stateTableData, setStateTableData] = useState([]);
    useEffect(() => {
        setStateTableData([...stateChromMethodData, ...stateReferenceSpectrumData])
    }, [stateGlobalReferenceSpectrumData, stateChromMethodData]);
    return <>
        {messageGetMethodByWikiId}
        <Row justify={"center"} align={"middle"}>
            <Col>
                {contextPlotMolecule}
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table
                    locale={{emptyText: 'Select a item from left to see the information.'}}
                    pagination={false}
                    size={'small'}
                    columns={column} dataSource={stateTableData}/>
            </Col>
        </Row>
    </>
};
export default Main;