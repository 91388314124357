import {Button, Col, Row, Flex} from 'antd';
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {BarChartOutlined, FileSearchOutlined, SearchOutlined} from "@ant-design/icons";

const LargeButton = ({icon, text, onClick}) => {
    const [stateHover, setHover] = useState(false);
    return <Button
        style={{
            width: "15em", height: "15em", fontSize: "1.5rem", borderRadius: "10%",
            borderColor: "#69b1ff", borderWidth: "2px",
            header: {cursor: "default"},
            boxShadow: stateHover ? "0.2em 0.5em 1em rgba(0,0,0,.11)" : null,
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <p align={"center"} style={{marginTop: "2em", marginBottom: "2em"}}>
            {icon}
        </p>
        <p align={"center"} style={{marginTop: "2em", marginBottom: "2em"}}>
            {text}
        </p>
    </Button>
}

export default () => {
    const navigate = useNavigate();
    const iconStyle = {fontSize: '4.5rem', color: '#69b1ff'};
    return <>
        <Row justify={"center"} align={"middle"} gutter={[16, 16]}
             style={{marginRight: 0, marginLeft: 0, marginTop: "5em"}}>
        </Row>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/*<Row justify={"center"} align={"middle"} gutter={[16, 16]}*/}
            {/*     style={{marginRight: 0, marginLeft: 0}}>*/}
            {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh'}}>*/}
            <Flex wrap gap="large" align={"center"} justify={"center"}>
                <p align={"center"}>
                    <LargeButton icon={<SearchOutlined style={iconStyle}/>}
                                 text={"Analyze one spectrum"}
                                 onClick={() => navigate("/_upload_spectrum")}/>
                </p>
                <p align={"center"}>
                    <LargeButton icon={<FileSearchOutlined style={iconStyle}/>}
                                 text={"Analyze multiple spectra"}
                                 onClick={() => navigate("/_upload")}/>
                </p>
                <p align={"center"}>
                    <LargeButton icon={<BarChartOutlined style={iconStyle}/>}
                                 text={"Access Binbase spectra"}
                                 onClick={() => navigate("/_binbase")}/>
                </p>
            </Flex>
            {/*</div>*/}
            {/*</Row>*/}
        </div>
        <Row justify={"center"} align={"middle"} gutter={[16, 16]}
             style={{marginRight: 0, marginLeft: 0, marginTop: "5em"}}>
        </Row>
    </>;
}

