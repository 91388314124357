export const zip = (...arrays) => {
    // Check if any of the arrays is null or undefined
    if (arrays.some(array => array == null)) {
        return [];
    }

    // Find the length of the shortest array
    const length = Math.min(...arrays.map(arr => arr.length));
    return Array.from({length}, (value, index) => {
        return arrays.map(array => array[index]);
    });
}
