import React, {useRef, useEffect, useContext, useState} from 'react';
import {useAtom, atom} from "jotai";
import SmilesDrawer from 'smiles-drawer';

const atomPlotMoleculeSmiles = atom("");

function PlotMolecule2(props) {
    const [getAtomPlotMoleculeSmiles,] = useAtom(atomPlotMoleculeSmiles);
    const refPlot = useRef(null)
    useEffect(() => {
        import('smiles-drawer').then(SmilesDrawer => {
            if (getAtomPlotMoleculeSmiles && getAtomPlotMoleculeSmiles.length > 0 && refPlot.current) {
                let option = {
                    width: props.width,
                    height: props.height,
                    compactDrawing: true,
                    padding: 0,
                }
                let smilesDrawer = new SmilesDrawer.Drawer(option)

                SmilesDrawer.parse(getAtomPlotMoleculeSmiles, function (tree) {
                    smilesDrawer.draw(tree, refPlot.current, "light", false)
                }, function (err) {
                    console.log("Error in plot molecule:", err, getAtomPlotMoleculeSmiles)
                    SmilesDrawer.parse("C", function (tree) {
                        let option = {
                            width: props.width,
                            height: 0,
                            compactDrawing: true,
                            padding: 0,
                        }
                        let smilesDrawer = new SmilesDrawer.Drawer(option)
                        smilesDrawer.draw(tree, refPlot.current, "light", false)
                    })
                })
            }
        })
    }, [getAtomPlotMoleculeSmiles])

    return <> {
        getAtomPlotMoleculeSmiles ? <>
            <canvas ref={refPlot} width={props.width} height={props.height} {...props}/>
        </> : <></>
    } </>
}

export const usePlotMolecule2 = (props) => {
    const refPlot = useRef(null)
    const contextPlotMolecule = <canvas ref={refPlot} width={props.width} height={props.height} {...props}/>
    const plotEmptyMolecule = (SmilesDrawer) => {
        SmilesDrawer.parse("C", function (tree) {
            let option = {
                width: props.width,
                height: 0,
                compactDrawing: true,
                padding: 0,
            }
            let smilesDrawer = new SmilesDrawer.Drawer(option)
            smilesDrawer.draw(tree, refPlot.current, "light", false)
        })
    }
    const setPlotMoleculeSmiles = (smiles) => {
        import('smiles-drawer').then(SmilesDrawer => {
            if (smiles && smiles.length > 0 && refPlot.current) {
                let option = {
                    width: props.width,
                    height: props.height,
                    compactDrawing: true,
                    padding: 0,
                }
                let smilesDrawer = new SmilesDrawer.Drawer(option)

                SmilesDrawer.parse(smiles, function (tree) {
                    smilesDrawer.draw(tree, refPlot.current, "light", false)
                }, function (err) {
                    // console.log("Error in plot molecule:", err, smiles)
                    plotEmptyMolecule(SmilesDrawer)
                })
            } else {
                plotEmptyMolecule(SmilesDrawer)
            }
        })
    }
    return [contextPlotMolecule, setPlotMoleculeSmiles];
}

export const usePlotMolecule = (props) => {
    const [statePlotMoleculeSmiles, setPlotMoleculeSmiles] = useState("");
    const contextPlotMolecule = statePlotMoleculeSmiles ?
        <PlotMolecule width={props.width} height={props.height} smiles={statePlotMoleculeSmiles}/> : <></>
    return [contextPlotMolecule, setPlotMoleculeSmiles];
}

export const PlotMolecule = (props) => {
    const refPlot = useRef(null)
    useEffect(() => {
        // console.log(props.smiles)
        import('smiles-drawer').then(SmilesDrawer => {
            // console.log("PlotMolecule", props.smiles)
            if (props.smiles && props.smiles.length > 0 && refPlot.current) {
                let option = {
                    width: props.width,
                    height: props.height,
                    compactDrawing: true,
                    padding: 0,
                }
                let smilesDrawer = new SmilesDrawer.Drawer(option)

                SmilesDrawer.parse(props.smiles, function (tree) {
                    smilesDrawer.draw(tree, refPlot.current, "light", false)
                }, function (err) {
                    console.log("Error in plot molecule:", err, props.smiles)
                })
            }
        }).catch((err) => {
            console.log("Error in plot molecule:", err)
        })
    }, [props.smiles, refPlot.current])

    return <>
        <canvas ref={refPlot} width={props.width} height={props.height} {...props}/>
    </>
}
