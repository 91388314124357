import {atom, useAtom} from "jotai/index";
import {atomGlobalReferenceSpectrumData, atomGlobalSpectrumData} from "../../../Data/Atoms";
import {useEasyGetRequest} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useEffect} from "react";

export const atomAnnotationSearchTableData = atom({});
export const atomAnnotationSearchTableDataTrigger = atom({wikiIdList: [], version: 0});

export const atomAnnotationSearchSelectedDataTrigger = atom({wikiId: "", version: 0});

export default () => {
    const [stateAtomGlobalSpectrum,] = useAtom(atomGlobalSpectrumData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomAnnotationSearchTableData
    const [stateAnnotationSearchTableData, setAnnotationSearchTableData] = useAtom(atomAnnotationSearchTableData);
    const [stateAnnotationSearchTableDataTrigger,] = useAtom(atomAnnotationSearchTableDataTrigger);

    // Get annotation data
    const [getAnnotationMultiData, messageGetAnnotationMultiData] =
        useEasyGetRequest(
            urlMassWiki.annotation.getManyByWikiId,
            () => setAnnotationSearchTableData({}),
            (data) => setAnnotationSearchTableData(data),
            "urlMassWiki.annotation.getManyByWikiId", "Loading annotation data..."
        );
    useEffect(() => {
        console.log("stateAnnotationSearchTableData", stateAnnotationSearchTableData)
    }, [stateAnnotationSearchTableData]);
    useEffect(() => {
        // console.log("stateAnnotationSearchTableDataTrigger", stateAnnotationSearchTableDataTrigger)
        if (stateAnnotationSearchTableDataTrigger.wikiIdList.length > 0) {
            getAnnotationMultiData.run(stateAnnotationSearchTableDataTrigger.wikiIdList)
        } else {
            setAnnotationSearchTableData({})
        }
    }, [stateAnnotationSearchTableDataTrigger]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomAnnotationSearchSelectedDataTrigger
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrumData);
    const [stateAnnotationSearchSelectedDataTrigger,] = useAtom(atomAnnotationSearchSelectedDataTrigger);

    // Get annotation data
    const [getAnnotationSingleData, messageGetAnnotationSingleData] =
        useEasyGetRequest(
            urlMassWiki.annotation.getOneByWikiId,
            () => setGlobalReferenceSpectrumData({}),
            (data) => {
                data.metadata = {...data.abstract_data, ...data.annotation, ...data.metadata}
                setGlobalReferenceSpectrumData(data)
            },
            "urlMassWiki.annotation.getOneByWikiId", "Loading selected annotation data..."
        );
    useEffect(() => {
        if (stateAnnotationSearchSelectedDataTrigger.wikiId) {
            getAnnotationSingleData.run(stateAnnotationSearchSelectedDataTrigger.wikiId)
        } else {
            setGlobalReferenceSpectrumData({})
        }
    }, [stateAnnotationSearchSelectedDataTrigger]);

    return <>
        {messageGetAnnotationMultiData}
        {messageGetAnnotationSingleData}
    </>
}