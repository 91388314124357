import {Button, Checkbox, Col, Row, Tabs, Tag} from 'antd';
import React, {useEffect, useState} from "react";
import {atom, useAtom} from "jotai";
import VirtualTable from "../../../Library/VirtualTable";
import {atomGlobalAnalysisData, atomGlobalSpectrumData} from "../../../Data/Atoms";
import {atomGlobalReferenceSpectrumData} from "../../../Data/Atoms";
import {atomShowModal, atomSubmissionData} from "./UserAnnotationSubmitModal";
import {
    atomAnnotationSearchTableData,
    atomAnnotationSearchTableDataTrigger,
    atomAnnotationSearchSelectedDataTrigger
} from "./AnnotationSearchResultData";
import AnnotationSearchResultData from "./AnnotationSearchResultData";
import {zip} from "../../../Library/Tools";
import SpectralListPlot from "../SpectralList/SpectralListPlot";

const getColumn = (stateGlobalReferenceSpectrumData, setPageSubmissionData) => {
    return [
        {
            title: 'Name', dataIndex: 'mol_name', key: 'mol_name', ellipsis: false, width: 180
        }, {
            title: 'Adduct', dataIndex: 'mol_adduct', key: 'mol_adduct', ellipsis: false, width: 80
        }, {
            title: 'Entropy similarity',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            defaultSortOrder: 'descend',
            ellipsis: false,
            width: 80,
            render: (_, record) => record.score.toFixed(3),
        }, {
            title: 'Δ RT',
            dataIndex: 'deltaRt',
            key: 'deltaRt',
            sorter: (a, b) => a.deltaRt - b.deltaRt,
            ellipsis: false,
            width: 50,
            render: (_, record) => (record.deltaRt === null) ? "-" : record.deltaRt.toFixed(3),
        }, {
            title: 'Δ m/z (Da)',
            dataIndex: 'deltaMz',
            key: 'deltaMz',
            sorter: (a, b) => a.deltaMz - b.deltaMz,
            ellipsis: false,
            width: 50,
            render: (_, record) => record.deltaMz.toFixed(3),
        }, {
            title: 'By', dataIndex: 'user_name', key: 'user_name', ellipsis: false, width: 80
        }, {
            title: "", width: 70,
            render: (_, record) => (
                stateGlobalReferenceSpectrumData.wiki_id === record.key ?
                    <Tag color={"red"} onClick={() => setPageSubmissionData(record)}>
                        Assign annotation
                    </Tag> : <>
                    </>
            )
        }
    ]
}

export default () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalAnalysisData);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrumData);
    // For search type
    const [stateSearchType, setSearchType] = useState("identity_search");

    ////////////////////////////////////////////////////////////////////////////////
    // Add assign annotation button
    const [, setAtomShowModal] = useAtom(atomShowModal);
    const [, setAtomSubmissionData] = useAtom(atomSubmissionData);
    const setPageSubmissionData = (record) => {
        console.log(record)
        setAtomSubmissionData({
            type: "user_annotation_search",
            mol_name: record.mol_name || "",
            mol_adduct: record.mol_adduct || "",
            mol_smiles: record.mol_smiles || "",
            db: record.db || "",
            db_id: record.db_id || "",
            score: record.score || 0,
            delta_mz: record.delta_mz || 0,
        })
        setAtomShowModal(true)
    }

    ////////////////////////////////////////////////////////////////////////////////
    const [stateSearchResults, setSearchResults] = useState({});
    // Dealing with different search types
    useEffect(() => {
        const search_result = (stateGlobalSpectrum.analysis || {}).annotation_search;
        if (search_result) {
            setSearchResults(search_result);
            if ((stateSearchResults.identity_search || []).length > 0) {
                setSearchType("identity_search")
            } else if ((stateSearchResults.open_search || []).length > 0) {
                setSearchType("open_search")
            } else if ((stateSearchResults.neutral_loss_search || []).length > 0) {
                setSearchType("neutral_loss_search")
            } else if ((stateSearchResults.hybrid_search || []).length > 0) {
                setSearchType("hybrid_search")
            } else {
                setSearchType("identity_search")
            }
        } else {
            setSearchResults({});
        }
    }, [stateGlobalSpectrum.analysis]);

    // //////////////////////////////////////////////////////////////////////////////
    // For get user annotation search table data
    const [stateAnnotationSearchTableDataTrigger, setAnnotationSearchTableDataTrigger] = useAtom(atomAnnotationSearchTableDataTrigger);
    useEffect(() => {
        const userAnnotationScoreList = stateSearchResults[stateSearchType] || [];
        if (userAnnotationScoreList && userAnnotationScoreList.length > 0) {
            setAnnotationSearchTableDataTrigger({
                ...stateAnnotationSearchTableDataTrigger, wikiIdList: userAnnotationScoreList.map((d) => d[0])
            })
        } else {
            setAnnotationSearchTableDataTrigger({wikiIdList: [], ...stateAnnotationSearchTableDataTrigger});
        }
    }, [stateSearchResults, stateSearchType])

    // For display user annotation search result
    const [stateAnnotationSearchTableData] = useAtom(atomAnnotationSearchTableData);
    const [stateTableData, setTableData] = useState(null);
    useEffect(() => {
        const userAnnotationScoreList = stateSearchResults[stateSearchType] || [];
        if (stateAnnotationSearchTableData) {
            // Get the analysis chromatogram data from stateGlobalRunData
            const globalChromWikiId = (((stateGlobalRunData.metadata || {}).chrom_method || {}).information || {}).wiki_id;
            const annotationData = zip(stateAnnotationSearchTableData, userAnnotationScoreList);
            const globalSpectrumRt = (stateGlobalSpectrum.abstract_data || {}).normalized_rt || (stateGlobalSpectrum.spectrum || {}).rt;
            const tableData = annotationData.map((d, i) => {
                const dAnnotation = d[0];
                const dScore = d[1][1];
                // console.log(globalChromWikiId, dAnnotation.rt, globalSpectrumRt)
                return {
                    key: dAnnotation.wiki_id,
                    mol_name: dAnnotation.mol_name,
                    mol_adduct: dAnnotation.mol_adduct,
                    chromWikiId: dAnnotation.chrom_wiki_id,
                    deltaRt: dAnnotation.chrom_wiki_id === globalChromWikiId ? dAnnotation.rt - globalSpectrumRt : null,
                    deltaMz: dAnnotation.precursor_mz - (stateGlobalSpectrum.spectrum || {}).precursor_mz,
                    score: dScore,
                    user_name: dAnnotation.user_name,
                    mol_smiles: dAnnotation.mol_smiles,
                }
            });
            // console.log("tableData", tableData);
            setTableData(tableData);
        } else {
            setTableData([]);
        }
    }, [stateAnnotationSearchTableData]);

    ////////////////////////////////////////////////////////////////////////////////
    const [stateGlobalReferenceSpectrumData, setLowerSpectrumData] = useAtom(atomGlobalReferenceSpectrumData);
    // For table column
    const column = getColumn(stateGlobalReferenceSpectrumData, setPageSubmissionData);
    // For selected spectrum
    const [stateAnnotationSearchSelectedDataTrigger, setAnnotationSearchSelectedDataTrigger] = useAtom(atomAnnotationSearchSelectedDataTrigger);


    const tabExtra = <>
        <Tag color={"red"} onClick={() => setPageSubmissionData({})}>
            Add annotation
        </Tag>
    </>

    return <>
        <AnnotationSearchResultData/>
        <Row>
            <Col span={24}>
                <Tabs defaultActiveKey="identity_search" centered onChange={(k) => setSearchType(k)}
                      activeKey={stateSearchType}
                      tabBarExtraContent={tabExtra}
                      items={[{
                          key: "identity_search", label: "Identity search",
                          disabled: (stateSearchResults.identity_search || []).length === 0
                      }, {
                          key: "open_search", label: "Open search",
                          disabled: (stateSearchResults.open_search || []).length === 0
                      }, {
                          key: "neutral_loss_search", label: "Neutral loss search",
                          disabled: (stateSearchResults.neutral_loss_search || []).length === 0
                      }, {
                          key: "hybrid_search", label: "Hybrid search",
                          disabled: (stateSearchResults.hybrid_search || []).length === 0
                      }]}/>
                <VirtualTable
                    vid={"user-annotation-search-result-table"}
                    onRow={record => {
                        return {
                            onClick: event => {
                                // console.log(record)
                                if (stateGlobalReferenceSpectrumData.wiki_id !== `${record.key}`) {
                                    setAnnotationSearchSelectedDataTrigger({
                                        wikiId: `${record.key}`,
                                        version: stateAnnotationSearchSelectedDataTrigger.version + 1
                                    });
                                }
                            }
                        };
                    }}
                    rowClassName={record => {
                        return (stateGlobalReferenceSpectrumData.wiki_id === `${record.key}`) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateTableData}/>
            </Col>
        </Row>
    </>;
};
