import {atom, useAtom} from "jotai/index";
import {atomGlobalReferenceSpectrumData, atomGlobalSpectrumData} from "../../../Data/Atoms";
import {useEasyGetRequest} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useEffect} from "react";

export const atomLibrarySearchTableData = atom({});
export const atomLibrarySearchTableDataTrigger = atom({wikiIdList: [], version: 0});

export const atomLibrarySearchSelectedDataTrigger = atom({wikiId: "", version: 0});

export default () => {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomLibrarySearchTableData
    const [stateLibrarySearchTableData, setLibrarySearchTableData] = useAtom(atomLibrarySearchTableData);
    const [stateLibrarySearchTableDataTrigger,] = useAtom(atomLibrarySearchTableDataTrigger);

    // Get Library data
    const [getLibraryMultiData, messageGetLibraryMultiData] =
        useEasyGetRequest(
            urlMassWiki.library.getManyByWikiId,
            () => setLibrarySearchTableData({}),
            (data) => setLibrarySearchTableData(data),
            "urlMassWiki.library.getManyByWikiId", "Loading library search result..."
        );
    // useEffect(() => {
    //     console.log("stateLibrarySearchTableData", stateLibrarySearchTableData)
    // }, [stateLibrarySearchTableData]);
    useEffect(() => {
        // console.log("stateLibrarySearchTableDataTrigger", stateLibrarySearchTableDataTrigger)
        if (stateLibrarySearchTableDataTrigger.wikiIdList.length > 0) {
            getLibraryMultiData.run(stateLibrarySearchTableDataTrigger.wikiIdList)
        } else {
            setLibrarySearchTableData({})
        }
    }, [stateLibrarySearchTableDataTrigger]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomLibrarySearchSelectedDataTrigger
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrumData);
    const [stateLibrarySearchSelectedDataTrigger,] = useAtom(atomLibrarySearchSelectedDataTrigger);

    // Get Library data
    const [getLibrarySingleData, messageGetLibrarySingleData] =
        useEasyGetRequest(
            urlMassWiki.library.getOneByWikiId,
            () => setGlobalReferenceSpectrumData({}),
            (data) => setGlobalReferenceSpectrumData(data),
            "urlMassWiki.library.getOneByWikiId", "Loading selected Library data..."
        );
    useEffect(() => {
        if (stateLibrarySearchSelectedDataTrigger.wikiId) {
            getLibrarySingleData.run(stateLibrarySearchSelectedDataTrigger.wikiId)
        } else {
            setGlobalReferenceSpectrumData({})
        }
    }, [stateLibrarySearchSelectedDataTrigger]);

    return <>
        {messageGetLibraryMultiData}
        {messageGetLibrarySingleData}
    </>
}