import React, {useEffect} from "react";
import {useAtom} from 'jotai'

import {urlMassWiki} from "../Config/MassWikiUrl";
import {atomGlobalAnalysisData, atomGlobalAnalysisDataTrigger, atomWikiID,} from "./Atoms";
import {useEasyGetRequest} from "../Pages/Global/Message";

export default () => {
    const [stateGlobalAnalysisTrigger,] = useAtom(atomGlobalAnalysisDataTrigger);

    /////////////////////////////////////////////////////////////////////////////////
    // For update atomGlobalAnalysisData
    const [stateGlobalAnalysisData, setGlobalAnalysisData] = useAtom(atomGlobalAnalysisData);
    useEffect(() => {
        console.log("stateGlobalAnalysisData", stateGlobalAnalysisData);
    }, [stateGlobalAnalysisData])

    const [getMassWikiAnalysisData, messageGetMassWikiAnalysisData] = useEasyGetRequest(
        urlMassWiki.analysis.getData,
        () => setGlobalAnalysisData({}),
        (data) => setGlobalAnalysisData(data),
        "urlMassWiki.analysis.getAnalysisData -> stateGlobalAnalysisData",
        "Loading MassWiki analysis data...")

    useEffect(() => {
        if (stateGlobalAnalysisTrigger.wikiId) {
            getMassWikiAnalysisData.run(stateGlobalAnalysisTrigger.wikiId);
        } else {
            getMassWikiAnalysisData.cancel();
        }
    }, [stateGlobalAnalysisTrigger]);


    return <>
        {messageGetMassWikiAnalysisData}
    </>
}
