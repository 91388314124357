import {Col, Row, Input, Table, Modal, Button, Spin, notification, message} from 'antd';
import React, {Suspense, useState, useEffect} from "react";
import {useAtom} from 'jotai'
import {urlMassWiki} from "../../Config/MassWikiUrl";

import {useEasyGetRequest} from "../Global/Message";
import {atomWebsiteMode, PERMISSION_TYPE} from "../../Data/Atoms";

export default () => {
    const [stateWebsiteMode,] = useAtom(atomWebsiteMode);
    useEffect(() => {
        if (stateWebsiteMode.permission === PERMISSION_TYPE.PUBLIC) {
            getBinbaseAllLibrary.run(PERMISSION_TYPE.PUBLIC);
        } else if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
            getBinbaseAllLibrary.run(PERMISSION_TYPE.INTERNAL);
        }
    }, [stateWebsiteMode.permission]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Update binbase library table
    const [stateBinbaseLibrary, setBinbaseLibrary] = useState([]);
    const funcProcessingLibraryList = (data) => {
        data.forEach((d) => {
            d.key = d.wiki_id ? d.wiki_id : d.name;
            d.time_updated = (new Date(d.time_updated)).toLocaleString();
        })
        console.log(data)
        return data;
    }
    const [getBinbaseAllLibrary, messageGetBinbaseAllLibrary] = useEasyGetRequest(
        urlMassWiki.binbase.getAllLibrary,
        () => setBinbaseLibrary([]),
        (data) => setBinbaseLibrary(funcProcessingLibraryList(data)),
        "getBinbaseAllLibrary", "Loading binbase library list...");

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For update button
    const [stateBinbaseUpdateMethod, setBinbaseUpdateMethod] = useState({});
    useEffect(() => {
        console.log("stateBinbaseUpdateMethod", stateBinbaseUpdateMethod)
    }, [stateBinbaseUpdateMethod]);

    const [getBinbaseUpdateMethod, messageGetBinbaseUpdateMethod] = useEasyGetRequest(
        urlMassWiki.binbase.update_binbase,
        () => setBinbaseUpdateMethod({}),
        (data) => notification.success({
            message: 'Success send request, please wait up to 1 hour for update to be finished',
        }),
        "urlMassWiki.binbase.update_binbase", "Updating method...");

    const columns = [
        {
            title: 'Method name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (<>{
                record.wiki_id ? <a href={`/${record.wiki_id}`}>{record.name}</a> : <>{record.name}</>
            }</>)
        }, {
            title: 'Spectral number',
            dataIndex: 'spectral_number',
            key: 'spectral_number',
            render: (text, record) => <>{record.status === "processing" ? <>
                <Spin/></> : record.spectral_number}</>
        }, {
            title: 'Updated time',
            dataIndex: 'time_updated',
            key: 'time_updated',
            render: (text, record) => <>{text}</>
        }];
    if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
        columns.push({
            title: '',
            key: 'action',
            render: (text, record) => <Button onClick={() => {
                getBinbaseUpdateMethod.run(record.name)
            }}>Update</Button>
        })
    }

    return <>
        {messageGetBinbaseAllLibrary}
        {messageGetBinbaseUpdateMethod}
        <Row justify={"center"}>
            <Col span={12}>
                <Table pagination={false} loading={getBinbaseAllLibrary.loading}
                       columns={columns} dataSource={stateBinbaseLibrary}/>
            </Col>
        </Row>
    </>
}
