import {Button, Col, Form, Input, message, Modal, Checkbox, Row} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {atom, useAtom} from "jotai";

import {usePlotMolecule} from "../../../Library/PlotMolecule";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {
    atomGlobalAnalysisData,
    atomGlobalSpectrumData,
    atomGlobalAnnotationDataTrigger,
    atomGlobalReferenceSpectrumData,
    atomUserInfo,
    atomUserInfoTrigger,
    atomWebsiteMode, SPECTRA_TYPE, PERMISSION_TYPE
} from "../../../Data/Atoms";
import {useEasyGetRequest} from "../../Global/Message";

export const atomSubmissionData = atom({
    type: "",
    mol_name: "",
    mol_adduct: "",
    mol_smiles: "",
    db: "",
    db_id: "",
    score: 0,
    delta_mz: 0,
});
export const atomShowModal = atom(false);

export default (props) => {
    // const refPlot = useRef(null)
    const [stateAtomGlobalRunData,] = useAtom(atomGlobalAnalysisData);
    const [stateAtomGlobalSpectrumData,] = useAtom(atomGlobalSpectrumData);
    const [stateAtomLowerSpectrumData,] = useAtom(atomGlobalReferenceSpectrumData);
    const [stateAtomWebsiteMode,] = useAtom(atomWebsiteMode);
    const [contextPlotMolecule, setAtomPlotMoleculeSmiles] = usePlotMolecule({width: 450, height: 200});

    const [stateAtomGlobalAnnotationDataTrigger, setAtomGlobalAnnotationDataTrigger] = useAtom(atomGlobalAnnotationDataTrigger);
    const [stateAtomShowModal, setAtomShowModal] = useAtom(atomShowModal);
    const [stateAtomSubmissionData,] = useAtom(atomSubmissionData);
    useEffect(() => {
        console.log("stateAtomSubmissionData", stateAtomSubmissionData)
    }, [stateAtomSubmissionData]);
    const [form] = Form.useForm();

    const [stateUserInfo,] = useAtom(atomUserInfo);
    const [, setUserInfoTrigger] = useAtom(atomUserInfoTrigger);

    // Show modal when the user is not logged in
    useEffect(() => {
        if (stateAtomShowModal && stateUserInfo.name === "") {
            setUserInfoTrigger(true)
        }
    }, [stateAtomShowModal])

    // Set the form values when the submission data is loaded
    useEffect(() => {
        // console.log(stateAtomSubmissionData)
        if (stateAtomSubmissionData) {
            const molSmiles = stateAtomSubmissionData.mol_smiles
            form.setFieldsValue(
                {
                    is_low_confidence: false,
                    is_low_quality: false,
                    type: stateAtomSubmissionData.type,
                    mol_name: stateAtomSubmissionData.mol_name,
                    mol_adduct: stateAtomSubmissionData.mol_adduct,
                    mol_smiles: molSmiles,
                    comments: stateAtomSubmissionData.type === "library_search" ?
                        `The compound matched with the ${stateAtomSubmissionData.db} library, identified by the ID: ${stateAtomSubmissionData.db_id}, demonstrated an entropy similarity score of ${(stateAtomSubmissionData.score || 0).toFixed(3)}. The mass error associated with this match was: ${(stateAtomSubmissionData.delta_mz || 0).toFixed(3)} Da.`
                        : "",
                }
            )
            setAtomPlotMoleculeSmiles(molSmiles)
        }
    }, [stateAtomSubmissionData])

    const [getSubmitAnnotation, messageSubmitAnnotation] = useEasyGetRequest(
        urlMassWiki.annotation.submit,
        () => {
        },
        (data) => {
            setAtomShowModal(false);
            setAtomGlobalAnnotationDataTrigger({version: stateAtomGlobalAnnotationDataTrigger.version + 1, ...stateAtomGlobalAnnotationDataTrigger});
            message.success("The annotation has been submitted successfully.");
        },
        "urlMassWiki.annotation.submit", "Submitting user annotation search results...");

    const submitUserAnnotation = () => {
        const submitData = form.getFieldsValue();
        const chromWikiId = stateAtomSubmissionData.chromWikiId || ""
        const allData = {
            analysis_wiki_id: stateAtomGlobalRunData.wiki_id,
            spectrum_wiki_id: stateAtomGlobalSpectrumData.wiki_id,
            chrom_wiki_id: chromWikiId,
            annotation: submitData,
            evidence: {
                library_wiki_id: stateAtomLowerSpectrumData.wiki_id,
            },
            spectrum: stateAtomGlobalSpectrumData.spectrum,
            additional_data: {
                spectra_type: SPECTRA_TYPE.USER,
            },
        }
        if (stateAtomWebsiteMode.mode === SPECTRA_TYPE.LCBINBASE && stateAtomWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
            const binbaseRunData = (stateAtomGlobalRunData.metadata || {}).binbase || {}
            const binbaseSpectrumData = (stateAtomGlobalSpectrumData.abstract_data || {})["binbase-splash"] || ""
            allData.additional_data.binbase = {
                'method': binbaseRunData.method || '',
                'version': binbaseRunData.version || '',
                'splash': binbaseSpectrumData || '',
            }
            allData.additional_data.spectra_type = SPECTRA_TYPE.LCBINBASE
        }
        if (stateUserInfo.name === "") {
            setUserInfoTrigger(true)
        } else {
            console.log(allData)
            getSubmitAnnotation.run(allData);
        }
    };

    return <>
        {messageSubmitAnnotation}
        <Modal title="Submit annotation" open={stateAtomShowModal} footer={null} width={650}
               destroyOnClose={true}
               onCancel={() => setAtomShowModal(false)}>
            <br/>
            <Form name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}}
                  initialValues={stateAtomSubmissionData}
                  form={form} onFinish={submitUserAnnotation}>

                <Form.Item label="Molecular name" name="mol_name" style={{marginBottom: "1em"}}>
                    <Input/>
                </Form.Item>

                <Form.Item label="SMILES" name="mol_smiles" style={{marginBottom: "1em"}}
                           onChange={(e) => {
                               setAtomPlotMoleculeSmiles(e.target.value)
                           }}
                           rules={[{required: false, message: 'SMILES is required!'}]}>
                    <Input/>
                </Form.Item>

                <Row justify={"center"}>
                    <Col>
                        <div>{contextPlotMolecule}</div>
                    </Col>
                </Row>

                <Form.Item label="Adduct" name="mol_adduct" style={{marginBottom: "1em"}}
                           rules={[{required: false, message: 'Adduct is required!'}]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Comments" name="comments" style={{marginBottom: "0em"}}>
                    <Input.TextArea rows={6}/>
                </Form.Item>

                <Form.Item label="" name="is_low_quality" style={{marginBottom: "0em"}}
                           valuePropName="checked" wrapperCol={{offset: 6, span: 16}}>
                    <Checkbox>This is a low quality EIC / spectrum</Checkbox>
                </Form.Item>

                <Form.Item label="" name="is_low_confidence" style={{marginBottom: "0em"}}
                           valuePropName="checked" wrapperCol={{offset: 6, span: 16}}>
                    <Checkbox>This is a low confidence annotation</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 10, span: 14}} style={{marginTop: "1em", marginBottom: "0em"}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    </>
}