import {Col, Row, Tabs, Space, Table, Tag} from 'antd';
import React, {useEffect, useState, useContext} from "react";
import {useAtom} from "jotai";
import Plot from 'react-plotly.js';

import {atomGlobalAnalysisData, atomGlobalSpectrumData} from "../../Data/Atoms";
import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useEasyGetRequest} from "../Global/Message";

const plotLayout = {
    xaxis: {title: {text: 'RT',}},
    yaxis: {title: {text: 'Intensity',}},
    autosize: true,
    hovermode: "closest",
    showlegend: false,
    margin: {l: 55, r: 10, b: 30, t: 10, pad: 0},
};

const plotConfig = {
    responsive: true,
    scrollZoom: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'zoom2d', 'pan2d'],
}

const BinbaseEIC = () => {
    const [stateEicData, setStateEicData] = useState({});
    const [statePlotData, setStatePlotData] = useState({
        data: [], layout: plotLayout, config: plotConfig
    });
    const [stateIsShow, setStateIsShow] = useState(false);
    const [getGlobalAnalysisData,] = useAtom(atomGlobalAnalysisData);
    const [getGlobalSpectrumData,] = useAtom(atomGlobalSpectrumData);
    const [getBinbaseEic, messageGetBinbaseEic] = useEasyGetRequest(
        urlMassWiki.binbase.getEic,
        () => {
            setStateEicData({});
            setStateIsShow(false)
        },
        (data) => {
            if (data.status === "success") {
                setStateEicData(data.data);
            }
        },
        "urlMassWiki.binbase.getEic", "Loading EIC data..."
    );

    useEffect(() => {
        const metaData = getGlobalAnalysisData.metadata;
        const specData = getGlobalSpectrumData.abstract_data;
        if (metaData && metaData.binbase && specData) {
            const binbaseMetaData = metaData.binbase;
            const binbaseData = {
                method: binbaseMetaData['method'],
                version: binbaseMetaData['version'],
                splash: specData['binbase-splash'],
            }
            // console.log('BinBase data:', binbaseData);
            getBinbaseEic.run(binbaseData);
        }
    }, [getGlobalSpectrumData, getGlobalAnalysisData]);

    useEffect(() => {
        console.log('EIC data:', stateEicData);
        const rt = (stateEicData.eic || {}).retention_times || [];
        const rtLeft = stateEicData.rt_left;
        const rtRight = stateEicData.rt_right;
        const intensity = (stateEicData.eic || {}).intensities || [];
        const maxIntensity = Math.max(...intensity);

        if (rt.length > 0 && intensity.length > 0) {
            setStateIsShow(true)
            const trace = {
                x: rt,
                y: intensity,
                mode: 'lines+markers',
                name: 'EIC',
                marker: {
                    size: 4
                },
            }
            const rangeLeft = {
                x: [rtLeft, rtLeft],
                y: [0, maxIntensity],
                mode: 'lines',
                marker: {
                    color: 'rgb(234, 153, 153)',
                    size: 12
                },
            }
            const rangeRight = {
                x: [rtRight, rtRight],
                y: [0, maxIntensity],
                mode: 'lines',
                marker: {
                    color: 'rgb(234, 153, 153)',
                    size: 12
                },
            }
            setStatePlotData({
                data: [trace, rangeLeft, rangeRight],
                layout: statePlotData.layout,
                config: statePlotData.config
            })
        }
    }, [stateEicData]);

    return <>
        {messageGetBinbaseEic}
        <Row justify={"center"}>
            <Col span={1}>
            </Col>
            <Col span={23}>
                {stateIsShow ?
                    <Plot
                        style={{position: 'relative', width: '100%', height: '300px'}}
                        useResizeHandler={true}
                        data={statePlotData.data} layout={statePlotData.layout} config={statePlotData.config}/> : <>
                        Loading EIC data...
                    </>}
            </Col>
        </Row>
    </>
};
export default BinbaseEIC;