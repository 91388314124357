import React, {useEffect, useMemo} from "react";
import {Table} from 'antd';
import {scrollTo, VList} from 'virtuallist-antd'

const VirtualTable = (props) => {
    const scrollToRow = props.scrollToRow;
    useEffect(() => {
        setTimeout(() => {
            if (scrollToRow && scrollToRow.row && scrollToRow.row > 0) {
                // console.log("ScrollTo", {row: props.scrollToRow, vid: props.vid})
                scrollTo({row: scrollToRow.row, vid: props.vid});
            }
        }, 500);
    }, [props.scrollToRow]);
    return <Table {...props}
                  pagination={false}
                  components={useMemo(() => {
                      return VList({
                          height: props.height,
                          resetTopWhenDataChange: false,
                          vid: props.vid || 'virtual-table',
                      })
                  }, [])}
                  scroll={{y: props.height}}/>
}
export default VirtualTable;
