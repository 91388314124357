import {Row, Col, InputNumber, Divider, Checkbox, Space, Alert, Button, Input, Select} from 'antd';
import React, {useState, useEffect} from 'react';
import {atom, useAtom} from "jotai";
import PlotSpectrum from "../../Library/PlotSpectrum";

export const atomSpectrum = atom({precursorMz: null, peaks: "", charge: 0});


const funcCleanPeaks = (peaks) => {
    if (peaks) {
        let peaks_clean = []
        // Determine the line number
        let allPeaksArray = peaks.split(/\r\n|\r|\n/).filter(n => n.trim())
        if (allPeaksArray.length > 1) {
            // Multiple lines, each line is a peak
            allPeaksArray.forEach(peak => {
                let peakArray = peak.split(/[^\d.eE\-+]+/).filter(n => n.trim()).map(parseFloat)
                if (peakArray.length >= 2) {
                    peaks_clean.push([peakArray[0], peakArray[1]])
                }
            })
        } else {
            // Single line, each peak is separated by some characters
            // Extract peaks
            peaks.match(/[\d.eE\-+]+/g).forEach(peak => {
                peaks_clean.push(parseFloat(peak))
            })

            // Sort peaks
            peaks_clean = peaks_clean.reduce((result, cur, index) => {
                if (index % 2 === 0) {
                    result.push([cur])
                } else {
                    result[result.length - 1].push(cur)
                }
                return result
            }, [])
        }

        // If last peak is not complete, remove it
        if ((peaks_clean[peaks_clean.length - 1] || []).length < 2) {
            peaks_clean.pop()
        }

        if (peaks_clean.length > 0) {
            return peaks_clean
        } else {
            return null
        }
    } else {
        return null
    }
}

const SpectrumInput = ({stateSpectrum, setSpectrum}) => {
    return <>
        <Row style={{marginTop: "8px"}}>
            <Space wrap={true}>
                <div style={{minWidth: "7em"}}>Charge</div>
                <Select
                    style={{width: "10em"}}
                    defaultValue={stateSpectrum.charge}
                    value={stateSpectrum.charge}
                    onChange={(value) => {
                        setSpectrum({...stateSpectrum, charge: value})
                    }}
                    options={[{value: 1, label: "Positive"}, {value: -1, label: "Negative"}]}
                />
            </Space>
        </Row>
        <Row style={{marginTop: "8px"}}>
            <Space wrap={true}>
                <div style={{minWidth: "7em"}}>Precursor m/z:</div>
                <Input
                    style={{minWidth: "7em"}}
                    value={stateSpectrum.precursorMz}
                    placeholder={"500.321"}
                    onChange={(e) => {
                        setSpectrum({...stateSpectrum, precursorMz: e.target.value})
                    }}/>
            </Space>
        </Row>
        <Row style={{marginTop: "8px"}}>
            Peaks:
        </Row>
        <Row style={{marginTop: "8px"}}>
            <Col span={24}>
                <Input.TextArea
                    allowClear={true}
                    rows="9"
                    placeholder={"300.321\t100.0\n300.321\t100.0\n (m/z) \t(intensity)\n..."}
                    value={stateSpectrum.peaks}
                    onChange={(e) => setSpectrum({...stateSpectrum, peaks: e.target.value})}/>
            </Col>
        </Row>
        <Row style={{marginTop: "8px"}}>
            <Space wrap={true}>
                <Button default onClick={() => {
                    setSpectrum({
                            charge: -1,
                            precursorMz: 353.0876507337427,
                            peaks: "191.0563507080078\t801574\n85.03010559082031\t125709\n135.0455780029297\t56939\n93.03517150878906\t51358\n173.04568481445312\t49469\n179.03504943847656\t40097\n87.00938415527344\t23480\n59.01425552368164\t22488\n161.02499389648438\t20605\n127.0405502319336\t17706\n133.02969360351562\t13624\n109.02973937988281\t10560\n111.04566192626953\t10235\n134.03775024414062\t8795\n67.0194320678711\t8575\n71.01425170898438\t8103\n81.03522491455078\t7645\n73.03003692626953\t6905\n155.03550720214844\t5574\n69.0351333618164\t4615\n99.0455551147461\t4609\n137.02462768554688\t4555\n83.05094909667969\t3974\n171.02975463867188\t3456\n57.035064697265625\t3424\n302.9432067871094\t2703\n261.1385803222656\t2554\n86.84629821777344\t2360\n259.1043701171875\t2345\n320.8838806152344\t2274\n83.01432800292969\t2149\n80.77784729003906\t2113\n164.2099609375\t2096\n55.42389678955078\t2028"
                        }
                    );
                }}>Try an example</Button>
                <Button default onClick={() => {
                    setSpectrum({precursorMz: null, peaks: ""});
                }}>Clear</Button>
            </Space>
        </Row>
    </>
}


export default () => {
    const [stateRawSpectrum, setRawSpectrum] = useState({precursorMz: null, peaks: ""});
    const [stateSpectrum, setSpectrum] = useAtom(atomSpectrum);

    useEffect(() => {
        const precursorMZ = parseFloat(stateRawSpectrum.precursorMz);
        const peaks = funcCleanPeaks(stateRawSpectrum.peaks);
        setSpectrum({...stateSpectrum, precursorMz: precursorMZ, peaks: peaks, charge: stateRawSpectrum.charge})
    }, [stateRawSpectrum])


    return <>
        <Row style={{marginTop: "8px", marginBottom: "0px"}}>
            <Col xs={24} sm={24} md={11} lg={11}>
                <SpectrumInput
                    stateSpectrum={stateRawSpectrum}
                    setSpectrum={setRawSpectrum}/>
            </Col>
            <Col xs={24} sm={24} md={2} lg={2}>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11}>
                {
                    stateSpectrum.peaks && stateSpectrum.peaks.length > 0 ?
                        <PlotSpectrum
                            loading={false}
                            data={{
                                specA: stateSpectrum
                            }}
                            height={"20em"}/> :
                        null
                }

            </Col>
        </Row>
    </>
}

