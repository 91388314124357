import {Button, Col, Row, Space, Upload, Spin, Progress, Radio, message} from 'antd';
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {atom, useAtom} from 'jotai'
import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useEasyGetRequest} from "../Global/Message";
import {atomWebsiteMode, PERMISSION_TYPE} from "../../Data/Atoms";

import ChromeMethod, {atomChromMethod} from "./ChromMethod";
import SampleInfo, {atomSampleInfo} from "./SampleInfo";
import UploadPanel, {atomUploadFileList} from "./UploadPanel";

export default () => {
    const navigate = useNavigate();
    const [stateUploadFileList,] = useAtom(atomUploadFileList)
    const [stateChromMethod,] = useAtom(atomChromMethod)
    const [stateSampleInfo,] = useAtom(atomSampleInfo)
    const [stateShowSubmitButton, setShowSubmitButton] = useState(false)
    useEffect(() => {
        // console.log(stateShowSubmitButton, stateChromMethodType, stateChromMethod, stateUploadFileList)
        setShowSubmitButton(false)
        if (stateChromMethod.chromType && stateUploadFileList.length > 0 && stateSampleInfo.charge) {
            if (stateChromMethod.chromType === "na") {
                setShowSubmitButton(true)
            }
            if (stateChromMethod.chromType !== "na" && stateChromMethod.wikiId) {
                setShowSubmitButton(true)
            }
        }
    }, [stateChromMethod.chromType, stateSampleInfo, stateChromMethod, stateUploadFileList])

    const [stateWebsiteMode,] = useAtom(atomWebsiteMode)
    const [getSubmitJob, messageContextHolder] = useEasyGetRequest(
        urlMassWiki.analysis.submitJob,
        () => {
        },
        (data) => {
            console.log(data)
            if (data.status === "success") {
                const wiki_id = data.wiki_id
                message.success("Job submitted successfully: " + wiki_id)
                navigate("/" + wiki_id)
            } else {
                console.error(data)
                message.error("Failed to submit job: " + data.message)
            }
        },
        "analysis.submitJob", "Submitting analysis, please wait for a few seconds...");
    const submitAnalysisJob = () => {
        console.log(stateWebsiteMode, PERMISSION_TYPE.INTERNAL)
        const analysisData = {
            source: stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL ? "internal" : "public",

            file_list: stateUploadFileList,
            file_type: stateSampleInfo.fileType,
            charge: stateSampleInfo.charge,

            chrom_method_id: stateChromMethod.wikiId || "",
            is_reference: stateChromMethod.is_reference || false,

            library_search_id_list: [],
        }
        console.log("Submit:", analysisData)
        getSubmitJob.run(analysisData)
    }

    return <>
        {messageContextHolder}
        <Row justify={"center"} align={"middle"} gutter={[16, 16]}
             style={{marginRight: 0, marginLeft: 0}}>
            <Col xs={24} sm={24} md={24} lg={6}/>
            <Col xs={24} sm={24} md={24} lg={12}>
                <div style={{width: "100%", minWidth: "8em", marginBottom: "0em", fontSize: "1.9rem"}}>
                    <div style={{marginTop: "1em", marginBottom: "1em",}}>
                        <UploadPanel/>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <SampleInfo/>
                    </div>
                    <div style={{marginTop: "0.5em"}}>
                        <ChromeMethod/>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <p align={"center"}>
                            <Button type={"primary"} onClick={submitAnalysisJob} disabled={!!!stateShowSubmitButton}>
                                Submit
                            </Button>
                        </p>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6}/>
        </Row>
        <br/>
    </>
}

