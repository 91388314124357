import {atom} from 'jotai'

export const atomWikiID = atom({id1: "", id2: "",});

// Atom for Global Run Data
export const atomGlobalAnalysisData = atom({});
export const atomGlobalAnalysisDataTrigger = atom({wikiId: "", version: 0});

// Atom for Global Spectrum Data
export const atomGlobalSpectrumData = atom({});
export const atomGlobalSpectrumDataTrigger = atom({wikiId: "", version: 0});

// Atom for Reference Spectrum Data
export const atomGlobalReferenceSpectrumData = atom({});
export const atomGlobalReferenceSpectrumDataTrigger = atom({wikiId: "", type: "", version: 0});

// Atom for Annotation Data
export const atomGlobalAnnotationData = atom([]);
export const atomGlobalAnnotationDataTrigger = atom({version: 0});

// Atom for User Info
export const atomUserInfo =
    atom({email: "", name: "", key: ""});
export const atomUserInfoTrigger = atom(false);

export const PERMISSION_TYPE = {
    PUBLIC: "public",
    INTERNAL: "internal"
}
export const SPECTRA_TYPE = {
    USER: "user",
    SINGLE_SPECTRUM: "single_spectrum",
    LCBINBASE: "lcbinbase",
}
export const atomWebsiteMode = atom({permission: PERMISSION_TYPE.PUBLIC, mode: SPECTRA_TYPE.USER});
