import React from 'react';
import {Button, Result, Spin, Space, Typography} from 'antd';
import {useAtom} from "jotai/index";
import {atomGlobalAnalysisData} from "../../Data/Atoms";
import {WarningOutlined} from "@ant-design/icons";

export default () => {
    const [stateGlobalAnalysisData,] = useAtom(atomGlobalAnalysisData);
    // Automatically refresh the page every 1 minute.

    if (stateGlobalAnalysisData.status === "processing") {
        setInterval(() => window.location.reload(), 60000)
        return <Result
            icon={<Spin size="large"/>}
            title="Processing..."
            extra={
                <Space direction={"vertical"}>
                    <Typography.Text style={{fontSize: 18, fontWeight: "normal"}}>
                        {stateGlobalAnalysisData.message}
                    </Typography.Text>
                    <Typography.Text style={{fontSize: 18, fontWeight: "normal"}}>
                        <Space>
                            <div>Please save this link to check back later:</div>
                            <Typography.Text copyable style={{
                                fontSize: 18,
                                fontWeight: "normal",
                                color: "#69b1ff"
                            }}>{window.location.href}</Typography.Text>
                        </Space>
                    </Typography.Text>
                    <Typography.Text style={{fontSize: 14, fontWeight: "normal"}}>
                        This page will automatically refresh every minute.
                    </Typography.Text>
                    <Button type="primary" style={{marginTop: "5em"}}
                            onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                </Space>
            }
        />
    } else {
        return <Result
            icon={<WarningOutlined style={{color: "#ff4d4f", fontSize: "5rem"}}/>}
            title="Error"
            extra={
                <Space direction={"vertical"}>
                    <Typography.Text style={{fontSize: 18, fontWeight: "normal"}}>
                        {stateGlobalAnalysisData.message}
                    </Typography.Text>
                    <Typography.Text style={{fontSize: 18, fontWeight: "normal"}}>
                        You can try to reanalyze the data.
                    </Typography.Text>
                    <Button type="primary" style={{marginTop: "5em"}}
                            onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                </Space>
            }
        />
    }

};

