import axios from "axios";
import Cookies from "js-cookie";

const urlMassWikiAPILambda = "https://ff5ppq2y0e.execute-api.us-west-2.amazonaws.com";
const urlMassWikiAPI = "https://masswiki.us-west-2.elasticbeanstalk.com";
// const urlMassWikiAPI = "http://localhost:8000";

const getMassWikiData = (wikiIdList, type, getDetails) =>
    axios.post(`${urlMassWikiAPI}/get/masswiki_data`, {
        wiki_id_list: wikiIdList,
        type: type,
        get_details: getDetails
    });

const axiosGet = (url, params) => {
    const cookiesInfo = Cookies.get()
    const userInfo = {
        "user-email": cookiesInfo.userEmail || "",
        "user-key": cookiesInfo.userKey || "",
    }
    return axios.get(url, {params: params, headers: userInfo}).then(res => res.data)
};

const axiosPost = (url, data) => {
    const cookiesInfo = Cookies.get()
    const userInfo = {
        "user-email": cookiesInfo.userEmail || "",
        "user-key": cookiesInfo.userKey || "",
    }
    return axios.post(url, data, {headers: userInfo}).then(res => res.data)
}

export const urlMassWiki = {
    // s3ForUploadRawFile: (filename) => `https://s3.us-west-2.amazonaws.com/upload.mass.wiki/${filename}`,
    uploadFile: `${urlMassWikiAPI}/uploads`,

    analysis: {
        submitJob: (data) =>
            axiosPost(`${urlMassWikiAPI}/analysis/submit`, data),

        submitOneSpectrum: (data) =>
            axiosPost(`${urlMassWikiAPI}/analysis/submit_one_spectrum`, data),

        getData: (wikiId) =>
            axiosGet(`${urlMassWikiAPI}/analysis/get_data`, {wiki_id: wikiId}),
    },

    chromMethod: {
        getMethodByWikiId: (wikiId) =>
            axios.get(`${urlMassWikiAPI}/chrom_method/get`,
                {params: {wiki_id: wikiId}}).then(res => res.data),

        searchMethod: (data) =>
            axios.get(`${urlMassWikiAPI}/chrom_method/search`, {params: {name: data}})
                .then(res => res.data),

        createMethod: (name, description) =>
            axios.post(`${urlMassWikiAPI}/chrom_method/create`, {name: name, description: description || ""})
                .then(res => res.data),
    },

    annotation: {
        getBySpectrumUuid: (spectrumUuid) =>
            axios.get(`${urlMassWikiAPI}/annotation/get_by_spectrum_uuid`, {params: {spec_uuid: spectrumUuid}})
                .then(res => res.data),
        getOneByWikiId: (wikiId) =>
            getMassWikiData([wikiId], "annotation", true)
                .then(res => res.data[0]),
        getManyByWikiId: (wikiIdList) =>
            getMassWikiData(wikiIdList, "annotation", false)
                .then(res => res.data),

        submit: ({
                     analysis_wiki_id,
                     spectrum_wiki_id,
                     chrom_wiki_id,
                     annotation,
                     evidence,
                     spectrum,
                     additional_data
                 }) =>
            axiosPost(`${urlMassWikiAPI}/annotation/submit`, {
                analysis_wiki_id: analysis_wiki_id,
                spectrum_wiki_id: spectrum_wiki_id,
                chrom_wiki_id: chrom_wiki_id,
                annotation: annotation,
                evidence: evidence,
                spectrum: spectrum,
                additional_data: additional_data
            })
    },

    user: {
        add: (name, email, password) =>
            axios.post(`${urlMassWikiAPI}/user/add`, {name: name, email: email, password: password})
                .then(res => res.data),
        activate: (email, activation_code) =>
            axios.post(`${urlMassWikiAPI}/user/activate`, {email: email, activation_code: activation_code})
                .then(res => res.data),
        login: (email, password) =>
            axios.get(`${urlMassWikiAPI}/user/login`, {params: {email: email, password: password}})
                .then(res => res.data),
        changePassword: (email, password, new_password) =>
            axios.post(`${urlMassWikiAPI}/user/change_password`, {
                email: email,
                password: password,
                new_password: new_password
            })
                .then(res => res.data),
        resetPassword: (email, reset_code, new_password) =>
            axios.post(`${urlMassWikiAPI}/user/change_password`, {
                email: email,
                reset_code: reset_code,
                new_password: new_password
            }).then(res => res.data),

        forgetPassword: (email) =>
            axios.post(`${urlMassWikiAPI}/user/forget_password`, {email: email})
                .then(res => res.data),
    },

    library: {
        getOneByWikiId: (wikiId) =>
            getMassWikiData([wikiId], "spectral_library", true)
                .then(res => res.data[0]),

        getManyByWikiId: (wikiIdList) =>
            getMassWikiData(wikiIdList, "spectral_library", false)
                .then(res => res.data),
    },

    universe: {
        search_universe_identity: (data) =>
            axios.post(`${urlMassWikiAPILambda}/search/universe_identity`, {...data, top_n: 100})
                .then(res => res.data),

        get_universe_spectrum: (unique_scan_id) =>
            axios.get(`${urlMassWikiAPILambda}/get/universe_spectrum/${unique_scan_id}`)
                .then(res => res.data),

        search_wcmc_identity: (data) =>
            axios.post(`${urlMassWikiAPILambda}/search/wcmc_identity`, {...data, cutoff: 0.75})
                .then(res => res.data),
    },

    binbase: {
        getAllLibrary: (target) =>
            axios.get(`${urlMassWikiAPI}/binbase/get_library_list`, {params: {source: target}})
                .then(res => res.data),

        update_binbase: (method) =>
            axios.post(`${urlMassWikiAPI}/binbase/update_method`, {method: method})
                .then(res => res.data),

        getEic: (data) => {
            const params = {
                method: encodeURIComponent(data.method),
                version: encodeURIComponent(data.version),
                splash: encodeURIComponent(data.splash)
            }
            return axios.get(`${urlMassWikiAPI}/binbase/get_eic`, {params})
                .then(res => res.data)
        }
    },
}
