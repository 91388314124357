import {Col, Row} from 'antd';
import React, {useEffect, useState} from "react";
import {useAtom} from "jotai";

import PlotSpectrum from "../../Library/PlotSpectrum";
import {atomGlobalSpectrumData} from "../../Data/Atoms";
import {atomGlobalReferenceSpectrumData} from "../../Data/Atoms";


const SpectraViewer = () => {
    const [statePlot, setStatePlot] = useState({
        specA: {
            precursorMz: null,
            peaks: null
        },
        specB: {
            precursorMz: null,
            peaks: null
        }
    });

    const [getAtomGlobalSpectrumData,] = useAtom(atomGlobalSpectrumData);
    const [getAtomLowerSpectrumData,] = useAtom(atomGlobalReferenceSpectrumData);
    useEffect(() => {
        const upperSpectrum = getAtomGlobalSpectrumData.spectrum || {};
        const lowerSpectrum = getAtomLowerSpectrumData.spectrum || getAtomLowerSpectrumData || {};
        setStatePlot({
            specA: {
                precursorMz: upperSpectrum.precursor_mz,
                peaks: upperSpectrum.peaks
            },
            specB: {
                precursorMz: lowerSpectrum.precursor_mz,
                peaks: lowerSpectrum.peaks
            }
        });
    }, [getAtomGlobalSpectrumData, getAtomLowerSpectrumData]);

    return <>
        <Row justify={"center"}>
            <Col span={1}>
            </Col>
            <Col span={23}>
                <PlotSpectrum data={statePlot} height={"300px"}/>
            </Col>
        </Row>
    </>
};
export default SpectraViewer;